<template>
  <div>
    <div class="">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        社会融资规模存量
      </div>
      <div class="mt-3 row justify-content-between">
        <div class="title_box" v-for="(item, index) in titList" :key="index">
          <div>
            <div>{{ item.year }}年(亿元)</div>
            <div style="font-size: 28px; font-weight: 550">
              {{ item.data }}
            </div>
            <div>
              <span>同比增长</span
              ><span
                :style="item.rate > 0 ? { color: '#00CEDC' } : { color: 'red' }"
                :class="
                  item.rate > 0 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
                "
                >{{ item.rate }}%</span
              >
            </div>
          </div>
          <div>
            <img :src="imageList[index].url" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>

    <div class="invets">
      <div class="invets_top">
        <div class="titles_s">
          <div class="titles_z">
            <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
            <span>社会融资规模增量</span>
          </div>
          <div class="text-right">
            <span>年份 </span>
            <el-select
              v-model="yearValue"
              placeholder="请选择"
              style="width: 100px"
              size="small"
              @change="changeYear"
            >
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div ref="in_L" class="mt-3" style="width: 100%; height: 350px"></div>
      </div>
    </div>

    <div class="hy">
      <div class="titles">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        地区社会融资规模增量
      </div>
      <div class="mt-2 row align-items-center justify-content-between">
        <div class="titles">{{ region }}社会融资规模增量</div>
        <div class="align-items-center">
          <span>地区 </span>
          <el-select
            v-model="region"
            placeholder="请选择"
            style="width: 100px"
            size="small"
            @change="changeRegion"
          >
            <el-option
              v-for="item in region_list"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mt-3" ref="in_H" style="width: 100%; height: 350px"></div>
    </div>
  </div>
</template>

<script>
import {
  finace_stock,
  finace_increment,
  finace_region_increment,
} from "@/api.js";

export default {
  data() {
    return {
      yearList: [],
      yearValue: "",
      region: "北京",
      region_list: [
        "上海",
        "云南",
        "内蒙古",
        "北京",
        "吉林",
        "四川",
        "天津",
        "宁夏",
        "安徽",
        "山东",
        "山西",
        "广东",
        "广西",
        "新疆",
        "江苏",
        "江西",
        "河北",
        "河南",
        "浙江",
        "海南",
        "湖北",
        "湖南",
        "甘肃",
        "福建",
        "西藏",
        "贵州",
        "辽宁",
        "重庆",
        "陕西",
        "青海",
        "黑龙江",
      ],

      titList: [],
      imageList: [
        {
          url: require("@/assets/img/arrow/icon1.png"),
        },
        {
          url: require("@/assets/img/arrow/icon2.png"),
        },
        {
          url: require("@/assets/img/arrow/icon3.png"),
        },
        {
          url: require("@/assets/img/arrow/icon4.png"),
        },
        {
          url: require("@/assets/img/arrow/icon5.png"),
        },
      ],
    };
  },
  mounted() {
    this.getfinace_stock();
    this.getfinace_increment();
    this.getfinace_region_increment();
  },
  created() {},
  computed: {},
  watch: {},
  filters: {},
  methods: {
    getfinace_stock() {
      finace_stock().then(({ data }) => {
        this.titList = data;
      });
    },
    getfinace_increment() {
      this.$echarts.init(this.$refs.in_L).dispose();

      finace_increment(this.yearValue).then(({ data }) => {
        this.yearList = data.year_list;
        this.yearValue = data.year;
        let month = data.month;

        let list = data.data;
        let series = [];
        for (let key in list) {
          series.push({
            name: key,
            type: "bar",
            stack: "total",
            barWidth: 50,
            data: list[key],
          });
        }
        var option = {
          color: [
            "#529EE2",
            "#B3E5FF",
            "#F7882B",
            "#00CEDC",
            "#FAD33F",
            "#F2637B",
            "#3EBB9F",
            "#705DF9",
          ],
          tooltip: {
            trigger: "axis",
            // axisPointer: {
            //   type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            // },
            order: "valueDesc",
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: month,
          },
          yAxis: {
            type: "value",
            name: "单位：亿元",
          },
          series: series,
        };
        this.$echarts.init(this.$refs.in_L).setOption(option);
      });
    },

    changeYear() {
      this.getfinace_increment();
    },
    changeRegion() {
      this.getfinace_region_increment();
    },
    getfinace_region_increment() {
      this.$loadingShow();
      finace_region_increment(this.region).then(({ data }) => {
        this.region = data.region;
        this.region_list = data.region_list;
        let month = data.month;
        let obj = data.data;
        let series = [];
        for (let key in obj) {
          series.push({
            name: key,
            type: "line",
            data: obj[key],
          });
        }

        var option = {
          color: [
            "#529EE2",
            "#B3E5FF",
            "#F7882B",
            "#00CEDC",
            "#FAD33F",
            "#F2637B",
            "#3EBB9F",
            "#705DF9",
          ],
          tooltip: {
            trigger: "axis",
            order: "valueDesc",
          },
          legend: {},
          grid: {
            left: "3%",
            right: "3%",
            bottom: "15%",
            containLabel: true,
          },

          xAxis: {
            type: "category",
            boundaryGap: false,
            data: month,
          },
          yAxis: {
            type: "value",
            name: "单位：亿元",
            // axisLabel: {
            //   formatter: "{value}",
            // },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 50,
            },
            {
              start: 0,
              end: 50,
            },
          ],
          series: series,
        };
        this.$echarts.init(this.$refs.in_H).setOption(option);
        this.$loadingHide();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.titles {
  font-size: 20px;
  font-weight: 550;
}
.titles_s {
  display: flex;
  justify-content: space-between;
  .titles_z {
    font-size: 20px;
    font-weight: 550;
    img {
      margin-right: 5px;
    }
  }
}
.title_box {
  padding: 15px 10px 15px 30px;
  width: 19%;
  height: 120px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invets {
  margin-top: 20px;
  .invets_top {
    width: 100%;
    padding: 15px 20px;
    border-radius: 5px;

    background: #fff;
  }
}

.hy {
  margin-top: 25px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background: #fff;
}
</style>